<template>
  <div>
    <!--table faculty-->
    <ek-table
      :columns="columns"
      :items="faculties"
      @delete-selected="deleteUsers"
      @details="detailsAppPanel"
    >
      <template slot="items.dateCreated" slot-scope="{ value }">
        <span> {{ new Date(value).toLocaleDateString() }}</span>
      </template>
      <template slot="items.universityId" slot-scope="{ props }">
        <span v-if="universityNames.find((u) => u.id == props.row.universityId)">
          {{ universityNames.find((u) => u.id == props.row.universityId).name }}
        </span>
      </template>
    </ek-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapState({
      columns: (state) => state.faculty.columns,
      universityNames: ({ university }) => university.universityName,
    }),
    ...mapGetters(["faculties"]),
  },
  methods: {
    ...mapActions(["getFacultyList", "getNamesUniversity"]),
    detailsAppPanel({ row }) {
      this.$store.commit("IS_DIALOG_OPEN", true);
      this.$store.commit("SET_FACULTY_DTO", row);
    },
    deleteUsers(e) {
      this.$store.dispatch("deleteFaculties", e);
    },
  },
  created() {
    this.getFacultyList();
    this.getNamesUniversity();
  },
};
</script>
